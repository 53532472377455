import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { LifeCycle, HeadContentSymbol, RendererSymbol } from '@wix/thunderbolt-symbols'
import { AppContext, AppProps, ClientRenderResponse, BatchingStrategy } from './types'
import { HeadContent } from './HeadContent'
import { ReactClientRenderer } from './clientRenderer/reactClientRenderer'
import { ReactDomLoader } from './clientRenderer/reactDomLoader'
import { PageMountUnmountSubscriber } from './clientRenderer/pageMountUnmountSubscriber'
import { BatchingStrategySymbol } from './symbols'
import { ClientBatchingStrategy } from './components/batchingStrategy'

export const site: ContainerModuleLoader = (bind) => {
	bind(RendererSymbol).to(ReactClientRenderer)
	bind(BatchingStrategySymbol).to(ClientBatchingStrategy)
	bind(LifeCycle.AppWillMountHandler).to(ReactDomLoader)
	bind(HeadContentSymbol).to(HeadContent)
	bind(LifeCycle.AppWillLoadPageHandler).to(PageMountUnmountSubscriber)
}

export { AppContext, AppProps, ClientRenderResponse, BatchingStrategy }

export { STYLE_OVERRIDES_ID, BatchingStrategySymbol } from './symbols'
