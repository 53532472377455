import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { Animations, name } from './symbols'
import { AnimationsFactory } from './animations'
import { LifeCycle } from '@wix/thunderbolt-symbols'
import { AnimationsInit } from './animationsInit'
import mediaResizeMap from 'santa-animations/src/mediaResizeMap'

export const page: ContainerModuleLoader = (bind) => {
	bind(Animations).to(AnimationsFactory)
	bind(LifeCycle.PageWillMountHandler).to(AnimationsInit)
}

export * from './types'
export { Animations, name, mediaResizeMap }
